import logo from "./imgs/logo.svg";
import sendMe from "./imgs/send-me.svg";
import couch from "./imgs/couch.svg";
import "./App.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    window.location.href = "https://namestone.xyz";
  }, []);

  return null;
  return (
    <div class="w-full flex-1 h-full min-h-[100vh] relative bg-stone-950 flex justify-start items-center flex-col">
      <div class="w-full pl-24 py-[24px] justify-start items-center inline-flex">
        <div class="self-stretch justify-start items-center inline-flex">
          <img
            src={logo}
            class="w-[160.50px] h-[36.47px] relative"
            alt="logo"
          />
        </div>
      </div>
      <div class="w-full flex-col justify-start items-center gap-6 inline-flex mt-[88px]">
        <div class="self-stretch flex-col justify-start items-center gap-4 flex">
          <div class="text-emerald-400 text-[40px] font-bold font-['Noto Sans']">
            Thank you for your support!
          </div>
          <div class=" text-center text-neutral-200 text-xl font-normal font-['Noto Sans']">
            nftychat is no longer active as of December 31, 2023.{" "}
          </div>
        </div>
        <div class="self-stretch  flex-col justify-start items-center gap-6 flex">
          <div class="self-stretch text-center text-neutral-200 text-xl font-bold font-['Noto Sans']">
            Looking for a decentralized chat experience?
          </div>
          <a
            class="px-8 py-4 bg-gradient-to-r from-yellow-400 to-green-500 rounded-[10px] justify-center items-center gap-2.5 inline-flex"
            href="https://www.sending.me/"
          >
            <div class="w-6 h-6 relative flex justify-center items-center">
              <img
                src={sendMe}
                alt="sendme"
                class="w-6 h-[17.18px] left-0 top-[3px]"
              />
            </div>
            <div class="text-center text-stone-950 text-xl font-medium font-['Rubik']">
              Try Sending.Me
            </div>
          </a>
        </div>
      </div>
      <div class="w-full flex-col justify-start items-center inline-flex mt-[112px] flex-1">
        <img class="w-[860px] h-[260px]" src={couch} alt="couch" />
      </div>

      <div class="w-[1440px] pl-24 pr-[1183.50px] pt-[23.50px] pb-[23.03px] left-0 top-0 justify-start items-center inline-flex">
        <div class="self-stretch justify-start items-center gap-[54px] inline-flex">
          <div class="w-[160.50px] h-[36.47px] relative"></div>
        </div>
      </div>
      <div class="w-full h-[52-px]  bg-zinc-900 ">
        <div class="justify-center items-center flex">
          <div class="text-neutral-200 text-[13px] font-normal font-['Noto Sans']">
            {" "}
            © 2022 nfty chat, inc.
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
